<template>
  <div>
    <title>SIM INVENTORY ~ DATA LIST GENERAL JOURNAL</title>
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <h1>
        Data ListGeneral Journal
        <br />
        <h4>
          The following is a list of data lists accounting general journal
        </h4>
        <!-- <small>Control panel</small> -->
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Dashboard</li>
      </ol>
    </section>

    <!-- Main content -->
    <section class="content">
      <!-- Small boxes (Stat box) -->
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>
      <div class="row">
        <div class="col-md-6">
                              <div class="box box-primary">
            <div class="box-header with-border">
              <i class="fa fa-save"></i>
              <h3 class="box-title">Save General Journal Data</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form" class="form-horizontal">
              <div class="box-body">
                <div class="form-group row">
                  <label for="exampleInputEmail1" class="col-sm-4 col-form-label">Number of evidence</label>
                  <input
                      type="hidden"
                      v-model="isidata.id"
                    />
                  <div class="col-sm-8">
                    <input
                      type="text"
                      v-model="isidata.nobukti"
                      autocomplete="off"
                      class="form-control"
                      placeholder="Number of evidence"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label for="exampleInputEmail1" class="col-sm-4 col-form-label">Evidence Date</label>
                  <div class="col-sm-8">
                    <input
                      type="date"
                      v-model="isidata.tglbukti"
                      autocomplete="off"
                      class="form-control"
                      placeholder="Evidence Date"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label for="exampleInputEmail1" class="col-sm-4 col-form-label">Description</label>
                  <div class="col-sm-8">
                    <input
                      type="text"
                      v-model="isidata.keterangan"
                      autocomplete="off"
                      class="form-control"
                      placeholder="Description"
                    />
                  </div>
                </div>
              </div>
              <div class="box-footer">
                <button
                  @click="save()"
                  type="button"
                  class="btn btn-primary"
                >
                  <i class="fa fa-save"></i>
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!-- <router-link to="/add-jurnalumum">
            <button type="button" class="btn btn-info">
              <i class="fa fa-plus"> Add Data </i>
            </button>
          </router-link> -->
          <div class="box box-primary">
            <div class="box-header">
              <div class="box-header with-border">
                <i class="fa fa-list"></i>
                <h3 class="box-title">General Journal Data Lists</h3>
              </div>
              <div class="box-body">
                <div class="row">
                  <div class="col-xs-5">
                    Show &nbsp; :
                    <select
                      v-model="pageSize"
                      @change="handlePageSizeChange($event)"
                    >
                      <option
                        v-for="size in pageSizes"
                        :key="size"
                        :value="size"
                      >
                        {{ size }}
                      </option>
                    </select>
                    results
                  </div>
                  <div class="col-xs-5">
                    <input
                      type="text"
                      autocomplete="off"
                      placeholder="Search by Number of evidence"
                      class="form-control"
                      v-model="namesearch"
                    />
                  </div>
                  <div class="col-xs-2">
                    <button
                      @click="searchdata()"
                      type="button"
                      class="btn btn-info"
                    >
                      <i class="fa fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <!-- /.box-header -->
            <div v-if="isExist" class="box-body">
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <!-- <th>Idx</th> -->
                      <th>Number of evidence</th>
                      <th>Evidence Date</th>
                      <th>Description</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(classdata, index) in list_paged"
                      :key="classdata.ID"
                    >
                      <td>
                        {{ index + 1 + (page - 1) * pageSize }}
                      </td>
                      <!-- <td>{{ classdata.id }}</td> -->
                      <td>{{ classdata.no_bukti }}</td>
                      <td>{{ classdata.tanggal_bukti }}</td>
                      <td>{{ classdata.keterangan }}</td>
                      <td>
                          <button
                            title="Edit General Journal"
                            class="btn btn-info"
                            @click="edit(classdata)"
                          >
                            <i class="fa fa-edit"></i>
                          </button> &nbsp;
                        <router-link
                          :to="'/list-jurnalumum/' + classdata.no_bukti"
                        >
                          <button
                            title="View Detail General Journal"
                            class="btn btn-success"
                          >
                            <i class="fa fa-list"></i>
                          </button> </router-link
                        >&nbsp;
                        <button
                          title="Delete General Journal"
                          @click="deleteData(classdata.id)"
                          class="btn btn-danger"
                        >
                          <i class="fa fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <v-pagination
                  v-model="page"
                  :pages="count"
                  :range-size="1"
                  active-color="#DCEDFF"
                  @update:modelValue="handlePageChange"
                />
              </div>
            </div>

            <div v-else class="box-body">
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <!-- <th>Idx</th> -->
                      <th>Number of evidence</th>
                      <th>Evidence Date</th>
                      <th>Description</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colspan="5" class="text-center">
                          <b style="color:red"> ... Data Not Found ... </b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- /.box-body -->
          </div>
        </div>
      </div>
      <!-- /.row -->
      <!-- Main row -->
    </section>
  </div>
</template>

<script>
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

import swal from "sweetalert";
import axios from "axios";

export default {
  name: "klasifikasiartikel",
  components: {
    VPagination
  },
  data() {
    return {
      isShow: false,
      isExist: true,
      loading: false,
      isidata: {
        id:"",
        nobukti: "",
        tglbukti: "",
        keterangan: "",
      },
      namesearch: "",
      list_paged: [],
      page: 1,
      pagestat: 1,
      count: 1,
      pageSize: 10,
      pageSizes: [10, 50, 100, 500]
    };
  },
  created() {
    this.fetchData();
    this.getnow();
  },
  methods: {
    handlePageChange(value) {
      this.page = value;
      this.pagestat = 1;
      // window.scrollTo({ top: 0, behavior: "smooth" });
      if (this.namesearch == "") {
        this.fetchData();
      } else {
        this.searchdata();
      }
    },

    handlePageSizeChange(event) {
      this.pageSize = event.target.value;
      this.page = 1;
      if (this.namesearch == "") {
        this.fetchData();
      } else {
        this.searchdata();
      }
    },
    getRequestParams(page, pageSize) {
      let params = {};

      if (page) {
        params["page"] = page;
      }

      if (pageSize) {
        params["rowcount"] = pageSize;
      }

      return params;
    },
    searchdata() {
      this.loading = true;
      const params = this.getRequestParams(this.page);
      const tokenlogin = sessionStorage.getItem("token");
      const urlAPIget =
        this.$apiurl +
        "jurnal_umum_h/getalldatajurnal_umum_h?cari=" +
        this.namesearch +
        "&length=" +
        this.pageSize;
      axios
        .get(urlAPIget, { params, headers: { Authorization: tokenlogin } })
        .then((resp) => {
          if (resp.data.data.length == 0) {
            this.isExist = false;
          } else {
            this.isExist = true;
            this.list_paged = resp.data.data;
            this.count = resp.data.datatotalcount;
          }
          this.loading = false;
        })
        .catch((err) => {
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data
          });
        });
    },
    async fetchData() {
      this.loading = true;
      const tokenlogin = sessionStorage.getItem("token");
      const urlAPIget =
        this.$apiurl +
        "jurnal_umum_h/getalldatajurnal_umum_h?length=" +
        this.pageSize +
        "&page=" +
        this.page;
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers: headers })
        .then((resp) => {
          if (resp.data.data.length == 0) {
            this.isExist = false;
          } else {
            this.isExist = true;
            this.list_paged = resp.data.data;
            this.count = resp.data.datatotalcount;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err.response);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data
          });
        });
    },
    deleteData(id) {
      this.loading = true;
      swal({
        title: "Are you sure ?",
        text: "The selected data will be deleted and cannot be restored",
        icon: "warning",
        buttons: true,
        dangerMode: true
      }).then((willdelete) => {
        if (willdelete) {
          const tokenlogin = sessionStorage.getItem("token");
          const urlAPIbrgdel =
            this.$apiurl + "jurnal_umum_h/hapusjurnal_umum_h/" + id;
          const headers = {
            Accept: "application/json",
            Authorization: tokenlogin
          };
          axios
            .delete(urlAPIbrgdel, { headers: headers })
            .then((resp) => {
              if (resp.data.status == true) {
                swal({
                  icon: "success",
                  title: "Success",
                  text: "Delete data successfully"
                }).then(function() {
                  window.location.reload();
                });
              } else {
                swal({
                  icon: "warning",
                  title: "Warning",
                  text: "Delete data failed"
                }).then(function() {
                  window.location.reload();
                });
              }
            })
            .catch((err) => {
              swal({
                icon: "warning",
                title: "Warning",
                text: err.response.data
              });
            });
        } else {
          this.loading = false;
        }
      });
    },
    save(){
      this.loading = true;
      var nobuk = this.isidata.nobukti;
      var tglbuk = this.isidata.tglbukti;
      var ket = this.isidata.keterangan;
      if (nobuk == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Number of evidence can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (tglbuk == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Date evidence can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (ket == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Description can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else {
        const kodeuser = sessionStorage.getItem("kodeuser");
        const paramdata = {
          no_bukti: this.isidata.nobukti,
          tanggal_bukti: this.isidata.tglbukti,
          keterangan: this.isidata.keterangan,
          kode_user: kodeuser
        };

        const tokenlogin = sessionStorage.getItem("token");
        const headers = {
          Accept: "application/json",
          Authorization: tokenlogin
        };
        let urlAPIsavedata="";
        let ax=axios;
        if(this.isidata.id!=""){
          urlAPIsavedata = this.$apiurl + "jurnal_umum_h/updatejurnal_umum_h/" + this.isidata.id;
          ax=axios.put(urlAPIsavedata, paramdata, { headers });
        } else {
          urlAPIsavedata = this.$apiurl + "jurnal_umum_h/savejurnal_umum_h";
          ax=axios.post(urlAPIsavedata, paramdata, { headers });
        }
        
        ax
          .then((resp) => {
            if (resp.data.status == true) {
              swal({
                      icon: "success",
                      title: "Success",
                      text: "Save data successfully",
                      showConfirmButton: false
                    });
                    this.loading = false;
                    this.isidata.id = "";
                    this.isidata.nobukti = "";
                    this.isidata.tglbukti = "";
                    this.isidata.keterangan = "";
                    this.fetchData();
            } else {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Save data failed",
                showConfirmButton: false
              });
              this.loading = false;
            }
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
              showConfirmButton: false
            });
            console.log(err);
            this.loading = false;
          });
      }
    },
    edit(data){
      this.isidata.id=data.id;
      this.isidata.nobukti=data.no_bukti;
      this.isidata.tglbukti=data.tanggal_bukti;
      this.isidata.keterangan=data.keterangan;
    },
    getnow() {
      const today = new Date();
      const tahun = today.getFullYear();
      var month = today.getMonth();
      if (String(month).length == 1) {
        month = "0" + (month + 1);
      } else {
        month;
      }
      var tgl = today.getDate();
      if (String(tgl).length == 1) {
        tgl = "0" + tgl;
      } else {
        tgl;
      }
      // const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      this.isidata.tglbukti = tahun + "-" + month + "-" + tgl;
    },
  }
};
</script>
<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>
